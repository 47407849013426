import ProfileContent from "./ProfileContent";
import { useRef, useState } from "react";

const Profile = ({ signer, Loading, address }) => {
  function Menu() {
    let newState = isMenuOpen ? false : true;
    changeMenuState(newState);
    menuLineRef.current.classList.toggle("menu-line-down");
    menuSecondLineRef.current.classList.toggle("menu-line-up");
    mobileMenuRef.current.classList.toggle("isMobileShown");
  }
  
  let [isPopupOpen] = useState(false);
  let [isMenuOpen, changeMenuState] = useState(false);
  let menuLineRef = useRef(null);
  let menuSecondLineRef = useRef(null);
  let mobileMenuRef = useRef(null);

  return (
    <>
      <div className="profile">
        <ProfileContent
          isPopupOpen={isPopupOpen}
          signer={signer}
          Loading={Loading}
          address={address}
        />
      </div>
      <div className="menu" onClick={Menu}>
        <div className="menu-line" ref={menuLineRef}></div>
        <div className="menu-line"></div>
        <div className="menu-line" ref={menuSecondLineRef}></div>
      </div>
    </>
  );
};

export default Profile;
