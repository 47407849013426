import { useState } from "react";
import Alert from "../img/alert.svg";
import CloseImg from "../img/close.svg";
function InfoPopup(props) {
    let [isInfoPopupOpen, changePopupState] = useState(true);
    return (
        <>
        { isInfoPopupOpen === true &&
            <div className="popup-window info-popup">
            {!props.preventClose && 
                <img src={CloseImg} alt="" className="close-btn" onClick={() => changePopupState(prev => prev === true ? false : true)}/>
            }
            <div className="popup-info-content process-container">
                <img src={Alert} alt="" className="process-logo" />
                <div className="process-text">{props.text}</div>
            </div>
            </div>
        }   
        </>
    );
}
  
export default InfoPopup;