import LogoImg from "../img/logo_new.svg";

function Logo(props) {
  return (
    <div className="Logo">
      <a href="https://zano.org">
        <img src={LogoImg} className="logo-img" alt="Logo" />
      </a>
      <a href="https://zano.org">
        <div className="logo-text-container">
          <p className="logo-text">
            Zano<span>Wrapped</span>
          </p>
        </div>
      </a>
    </div>
  );
}

export default Logo;
