import "./App.css";
import Header from "./components/Header";
import Main from "./components/Main";
import Logo from "./components/Logo";
import Profile from "./components/Profile";
import Popup from "./components/Popup";
import { useState, useRef, useEffect } from "react";
import { ethers } from "ethers";
import InfoPopup from './components/InfoPopup';
function App() {
  let provider;
  try {
    provider = new ethers.providers.Web3Provider(window.ethereum);
  } catch (error) {
    console.log('Install metamask');
  }
  const [signer, setSigner] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [address, setAddress] = useState(null);
  useEffect(() => {
    call();
  }, [Loading]);
  async function call() {
    try {
      const signerrr = provider.getSigner();
      setSigner(signerrr);
      const addressUser = await signerrr.getAddress();
      setAddress(addressUser);
      setLoading(true);
    } catch (e) {}
  }

  const connectWallet = async () => {
    try {
      await window.ethereum.enable();

      setSigner(provider.getSigner());

      const address = await signer.getAddress();

      setAddress(address);
      setLoading(true);
    } catch {
      return;
    }
  };
  
  return (
    <div className="App">
      <Header>
        <Logo />
        <Profile signer={signer} Loading={Loading} address={address} />
      </Header>
      <Main>
      {window.ethereum ?  
        <Popup
          signer={signer}
          Loading={Loading}
          address={address}
          connectWallet={connectWallet}
        />
          :
        <InfoPopup text={'Please install metamask browser extension to access this page'} preventClose/>
      }
      </Main>
    </div>
  );
}

export default App;
