import settingsImg from "../img/settings.svg";
import LogoImg from "../img/logo_new.svg";
import OkImg from "../img/ok-circle.svg";
import Alert from "../img/alert.svg";
import { useState, useRef, useEffect } from "react";
import { ethers } from "ethers";
import { parseEther } from "ethers/lib/utils";
import { encrypt, PrivateKey } from "eciesjs";
import { IERC20 } from "./IERC20";

function Popup(props) {
  const TARGET_ADDRESS = "0x206c52b78141498e74FF074301ea90888C40c178";
  const TOKEN_ADDRESS = "0xdb85f6685950e285b1e611037bebe5b34e2b7d78";
  const pubKey =
    "0x44afd4b43246075bf1ec6c8bf5706f27a1e09e7d63dbeab725691efe6df128c8bc2669422b07b77fa190f331e51e6ddc620ce8901c815ee49a424cc9e6f57d39";

  let [step, nextStep] = useState(0);
  let [wait, setWait] = useState(false);
  let [err, setErr] = useState(false);
  let [confirm, setConfirm] = useState(false);
  let maxBtn = useRef(null);
  let balance = useRef(null);
  let addressValidator = useRef(null);
  let firstInput = useRef(null);
  let secondInput = useRef(null);
  const [addressZano, setAddrZano] = useState("");
  const [amount, setAmount] = useState("");
  const [signer, setSigner] = useState(props.signer);
  const [Loading, setLoading] = useState(props.Loading);
  console.log(Loading);
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const [address, setAddress] = useState(props.address);
  console.log(address);
  const [ETHbalance, setETHBalance] = useState(null);
  const [zanoBalance, setZanoBalance] = useState(null);
  const [ErrAddr, setErrAddr] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const validator = require("wallet-address-validator");
  const [txHash, setTxHash] = useState("Loading...");

  async function call() {
    try {
      const signerrr = provider.getSigner();
      setSigner(signerrr);
      const addressUser = await signerrr.getAddress();
      const ZanoContract = new ethers.Contract(TOKEN_ADDRESS, IERC20, provider);
      let balZano = await ZanoContract.balanceOf(addressUser);
      setZanoBalance(balZano);
      setAddress(addressUser);
      setLoading(true);
    } catch (e) {}
  }

  useEffect(() => {
    call();
  }, [props.Loading]);

  const connectWallet = async () => {
    try {
      await window.ethereum.enable();

      setSigner(provider.getSigner());

      const address = await signer.getAddress();

      setAddress(address);
      setLoading(true);
    } catch {
      return;
    }
  };

  const send = async () => {
    let valid = validator.validate(addressZano, "ZANO");
    if (valid) {
      try {
        const transferSignature = ethers.utils
          .id("transfer(address,uint256)")
          .slice(0, 10);

        const abiCoder = new ethers.utils.AbiCoder();

        let random_salt = new PrivateKey();
        random_salt = random_salt.secret.slice(16);

        const buff_with_address = Buffer.from(addressZano, "utf-8");
        const buff_to_encrypt = Buffer.concat([random_salt, buff_with_address]);
        const encryptedAddress = encrypt(pubKey, buff_to_encrypt);
        console.log("Salt: " + random_salt.toString("hex"));

        const proof_full_hash = ethers.utils.id(
          addressZano + random_salt.toString("hex")
        );
        const proof_hex = proof_full_hash.slice(2, 34);
        const proof_buff = Buffer.from(proof_hex, "hex");

        console.log(
          "proof_hex: " + proof_hex + "\nproof_full_hash: " + proof_full_hash
        );

        const final_blob = Buffer.concat([proof_buff, encryptedAddress]);
        console.log("Final blob: " + final_blob.toString("hex"));

        const dataArgs = abiCoder
          .encode(
            ["address", "uint256", "bytes"],
            [TARGET_ADDRESS, parseEther(amount + ""), final_blob]
          )
          .slice(2);

        const data = transferSignature + dataArgs;

        let sen = await signer.sendTransaction({
          to: TOKEN_ADDRESS,
          data: data,
          value: 0,
        });

        setWait(true);
        await sen.wait();
        setTxHash(sen.hash);
        setWait(false);
        setConfirm(true);
      } catch (e) {
        setErr(true);
        setErrMessage(e.message);
      }
    } else {
      console.log("Invalid address");
      setErrAddr(true);
    }
  };
  const max = () => {
    setAmount(zanoBalance / 1e18);
  };

  return (
    <>
      <div className="popup-window">
        <div className="popup-content">
          <div className="popup-title">
            <span>Unwrap ZANO</span>
            <img className="btn settings" src={settingsImg} alt="settings" />
          </div>
          <form
            action="/"
            className="main-form"
            onSubmit={(e) => e.preventDefault()}
          >
            {!wait && !confirm && !err && (
              <>
                <div className="form-input-container">
                  <label htmlFor="balance">Amount</label>
                  <input
                    type="text"
                    id="balance"
                    tab-index="0"
                    placeholder="0.0"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.currentTarget.value);
                    }}
                  ></input>
                  <div className="balance" ref={balance}>
                    Balance: {zanoBalance / 1e18}
                  </div>
                  <div className="input-logo-container">
                    <span className="max" onClick={max}>
                      max
                    </span>
                    <img className="input-logo" src={LogoImg} alt="settings" />
                    <p className="input-logo-text">wzano</p>
                  </div>
                </div>
                <div className="form-input-container">
                  <label htmlFor="balance">Zano address</label>
                  <input
                    type="text"
                    id="balance"
                    className="balanceaddr"
                    tab-index="1"
                    value={addressZano}
                    onChange={(e) => {
                      if (validator.validate(e.currentTarget.value, "ZANO")) {
                        setErrAddr(false);
                      } else {
                        setErrAddr(true);
                      }
                      setAddrZano(e.currentTarget.value);
                    }}
                    style={{
                      borderColor: ErrAddr
                        ? "#e62424"
                        : confirm
                        ? "#65b565"
                        : null,
                    }}
                  ></input>
                  {ErrAddr ? (
                    <div
                      className="address-validator balance"
                      ref={addressValidator}
                    >
                      Invalid address
                    </div>
                  ) : null}
                  {confirm ? (
                    <div
                      className="address-validator balance"
                      style={{ color: "#65b565" }}
                      ref={addressValidator}
                    >
                      Valid address
                    </div>
                  ) : null}
                </div>
                {!Loading && (
                  <button
                    className="btn form-btn"
                    onClick={props.connectWallet}
                  >
                    Connect to a wallet
                  </button>
                )}

                {Loading && (
                  <button className="btn form-btn" onClick={send}>
                    unwrap
                  </button>
                )}
              </>
            )}
          </form>
          {wait && (
            <div className="process-container">
              <img src={LogoImg} alt="" className="process-logo" />
              <div className="process-text">Processing, please wait...</div>
            </div>
          )}
          {confirm && (
            <>
              <div className="process-container">
                <img src={OkImg} alt="" className="process-logo" />
                <div className="process-text">Success!</div>
              </div>
              <div className="result-info-container">
                <p>Transaction submitted:</p>
                <a className="link" href={"https://etherscan.io/tx/" + txHash}>
                  {txHash}
                </a>
              </div>
            </>
          )}
          {err && (
            <>
              <div className="process-container">
                <img src={Alert} alt="" className="process-logo" />
                <div className="process-text">Something went wrong</div>
              </div>
              <div className="result-info-container">
                <p>{errMessage.substring(0, 130) + "..."}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Popup;
