import LogoImg from "../img/logo_new.svg";
import CloseImg from "../img/close.svg";
import CopyImg from "../img/copy.svg";
import ViewImg from "../img/view.svg";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { parseEther } from "ethers/lib/utils";
import { IERC20 } from "./IERC20";

const ProfileContent = (props) => {
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(null);
  const [ETHbalance, setETHBalance] = useState(null);
  const [zanoBalance, setZanoBalance] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const ZanoContract = new ethers.Contract(
          "0xdb85f6685950e285b1e611037bebe5b34e2b7d78",
          IERC20,
          provider
        );

        const signer = provider.getSigner();

        const addressUser = await signer.getAddress();

        setAddress(addressUser);

        let balETH = await provider.getBalance(addressUser);
        let balZano = await ZanoContract.balanceOf(addressUser);

        setETHBalance(+balETH);
        setZanoBalance(+balZano);

        setLoading(true);
      } catch (e) {
        console.log("Error");
      }
    })();
  }, [props.Loading]);

  let [isPopupOpen, changePopupState] = useState(false);
  function Popup(e) {
    e.preventDefault();
    let newState = isPopupOpen ? false : true;
    changePopupState(newState);
  }
  return (
    <>
      {loading && (
        <>
          <div className="profile-balance">
            <div className="btn profile-balance-border">
              <div className="profile-balance-btn">
                <a href="/" className="btn">
                  <span>{zanoBalance / 1e18} wzano</span>
                </a>
              </div>
            </div>
          </div>
          <div className="profile-account">
            <a href="/" className="btn profile-wallet" onClick={Popup}>
              <span>{(ETHbalance / 1e18).toFixed(4)} ETH</span>
            </a>
            <a href="/" className="btn profile-wallet" onClick={Popup}>
              <span>
                {address.slice(0, 6)} ... {address.slice(-5)}
              </span>
              <img src={LogoImg} alt="Logo" className="profile-wallet-img" />
            </a>
          </div>
        </>
      )}
    </>
  );
};
export default ProfileContent;
